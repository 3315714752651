<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <h1 class="mb-3 text-center">Change Password</h1>
        
        <v-card class="pa-6">
          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <v-alert
              v-model="showSuccess"
              dense
              outlined
              dismissible
              type="success"
            >
              Your password has been successfully updated!
            </v-alert>

            <v-text-field
              v-model="form.oldPassword"
              :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.oldPassword"
              :type="showOldPassword ? 'text' : 'password'"
              label="Old Password"
              name="old-password"
              counter
              @click:append="showOldPassword = !showOldPassword"
            ></v-text-field>
            
            <v-text-field
              v-model="form.newPassword"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.newPassword"
              :type="showNewPassword ? 'text' : 'password'"
              label="New Password"
              name="password"
              counter
              @click:append="showNewPassword = !showNewPassword"
            ></v-text-field>

            <v-text-field
              v-model="form.confirmPassword"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.confirmPassword"
              :type="showConfirmPassword ? 'text' : 'password'"
              label="Confirm New Password"
              name="confirm_password"
              counter
              @click:append="showConfirmPassword = !showConfirmPassword"
            ></v-text-field>

            <v-alert
              v-model="showError"
              dense
              outlined
              dismissible
              type="error"
            >{{errorMessage}}</v-alert>

            <v-btn
              type="submit"
              color="primary"
              class="mr-2"
              :disabled="!valid"
              :loading="submitting"
            >Update</v-btn>

            <v-btn
              color="error"
              plain
              @click="$router.back()"
            >Cancel</v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  title: 'Change Password',

  data: function() {
    return ({
      valid: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [
          v => !!v || 'Old password is required'
        ],
        newPassword: [
          v => !!v || 'New password is required',
        ],
        confirmPassword: [
          v => !!v || 'Password confirmation is required',
          v => v === this.form.newPassword || 'Password confirmation must match new password'
        ]
      },
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      showError: false,
      errorMessage: '',
      showSuccess: false
    })
  },

  computed: {
    ...mapGetters({
      submitting: 'auth/submitting'
    })
  },

  methods: {
    ...mapActions({
      changePassword: 'auth/changePassword'
    }),
    submit: function() {
      if (!this.submitting) {
        this.changePassword(this.form)
          .then(() => {
            this.$refs.form.reset()
            this.showSuccess = true
          })
          .catch(error => {
            this.errorMessage = error.message
            this.showError = true
          })
      }
    }
  }
}
</script>
